import React, { Component } from 'react'
import Link from '../../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { DownArrow } from '../icons'
import Fade from 'react-reveal/Fade'

class ProjectBanner extends Component {

  viewProject = () => {
    const sp = document.querySelector('.single-project')
    if (!sp) return;
    const $snapWrapper = document.querySelector('.snap-wrapper')
    const headerHeight = window.innerWidth < 900 ? 62 : 93
    const sliderHeight = window.innerHeight * 2
    window.scrollTo({ top: sliderHeight })
    const scrollPos = $snapWrapper.scrollTop + sp.getBoundingClientRect().top - headerHeight
    $snapWrapper.scrollTo({ top: scrollPos, behavior: 'smooth' })
  }

  render() {

    let { title, acf, uri, index, length } = this.props

    return (
      <div className='project-banner'>
        <Fade bottom distance='20px' delay={1500}><p className='project-banner__tagline' dangerouslySetInnerHTML={{ __html: acf.homeSlider.tagline }} /></Fade>
        <div className='project-banner__image'>
          {acf.homeSlider.image?.localFile && <GatsbyImage loading='lazy' image={getImage(acf.homeSlider.image?.localFile)} alt={acf.homeSlider.title} />}
          <Fade bottom distance='40px' delay={800}><div className='project-banner__title-wrap'><h4 className='project-banner__title' dangerouslySetInnerHTML={{ __html: acf.homeSlider.title }} /></div></Fade>
        </div>
        <Fade bottom distance='20px' delay={1500}>
          <div className='project-banner__links'>
            <span>{index} / {length}</span>
            <Link to={uri} onClick={this.viewProject}>
              View Project
            </Link>
            <button>
              <DownArrow />
            </button>
          </div>
        </Fade>
      </div>
    )
  }
}

export default ProjectBanner