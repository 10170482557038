import { useStaticQuery, graphql } from "gatsby"

const useCategoryQuery = () => {
  const { allWpCategory } = useStaticQuery(graphql`
    query Categories {
      allWpCategory(filter: {count: {gt: 0}}) {
        nodes {
          name
          slug
        }
      }
    }
  `)

  return allWpCategory.nodes
}

export default useCategoryQuery