import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../../utils/link'
import { NextArrow, PrevArrow } from '../../utils/slider-arrows'
import Fade from 'react-reveal/Fade'

import Slider from 'react-slick'
import ShareLinks from '../../utils/share-links'

const PostGallery = ({ gallery }) => {

  const [slideIndex, setSlideIndex] = React.useState(1)

  const settings = {
    speed: 600,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    cssEase: 'ease',
    swipe: false,
    focusOnSelect: false,
    adaptiveHeight: true,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setSlideIndex(next + 1)
  }

  return (
    <div className='article__gallery'>
      <Slider {...settings}>
        {gallery?.map((el, i) => (
          <div className='article__gallery__image' key={i}>
            <GatsbyImage image={getImage(el.localFile)} alt={el.title || el.altText || el.caption} />
            <span dangerouslySetInnerHTML={{ __html: el.caption }} />
          </div>
        ))}
      </Slider>
      <span className='article__gallery__image-length'>{slideIndex} / {gallery?.length || '1'}</span>
    </div>
  )
}

const Post = ({ title, featuredImage, date, content, onClick, onClose, i, acf, uri }) => {

  const renderContent = (item, i) => {
    if (item.__typename === 'WpPost_Acf_Content_Text') {
      return (
        <div key={i} className='article__content' dangerouslySetInnerHTML={{ __html: item.content }} />
      )
    }
    if (item.__typename === 'WpPost_Acf_Content_Gallery') {
      return (<PostGallery key={i} gallery={item.gallery} />)
    }
  }

  return (
    <div className='article'>
      <div className='article__inner'>
        <span className='article__date'>{date}</span>
        <div className='article__header'>
          <h1>{title}</h1>
          <button onClick={() => onClose(i)}>
            <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="1.29289" y1="26.2929" x2="26.2929" y2="1.29289" stroke="black" strokeWidth="2" />
              <line x1="1.70711" y1="1.29289" x2="26.7071" y2="26.2929" stroke="black" strokeWidth="2" />
            </svg>
          </button>
        </div>
        <div className='article__wrapper'>
          <div className='article__sidebar'>
            <h4>Share</h4>
            <div className='article__share'>
              <ShareLinks uri={uri} title={title} />
            </div>
            <div className='article__nav'>
              <button onClick={() => onClick(i, 'prev')}>Previous article</button><span>/</span><button onClick={() => onClick(i, 'next')}>Next article</button>
            </div>
          </div>
          <div className='article__body'>
            {content && <div className='article__content' dangerouslySetInnerHTML={{ __html: content }} />}
            {acf?.content?.map(renderContent)}
          </div>
        </div>
      </div>
    </div>
  )
}

Post.defaultProps = {
  acf: { content: [] },
}

export default Post

