import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../../utils/link'
import Fade from 'react-reveal/Fade'

const ArticleCard = ({ title, uri, featuredImage, date, excerpt, onClick, acf, i }) => {

  let text = acf.content?.find(item => item.__typename === 'WpPost_Acf_Content_Text')

  return (
    <Fade distance='40px' bottom>
      <div className='card card--article' onClick={() => onClick(i)}>
        <div className='card__image'>
          { featuredImage?.node?.desktopImage && <GatsbyImage loading='lazy' className='card__image-desktop' image={getImage(featuredImage.node.desktopImage)} alt={title} /> }
          { featuredImage?.node?.mobileImage && <GatsbyImage loading='lazy' className='card__image-mobile' image={getImage(featuredImage.node.mobileImage)} alt={title} /> }
        </div>
        <span className='card__date'>{date}</span>
        <h4 className='card__title' dangerouslySetInnerHTML={{ __html: title }} />
        <div className='card__excerpt' dangerouslySetInnerHTML={{ __html: excerpt || text?.content || 'nbsp;' }} />
        <span className='card__link' to={uri}>View post</span>
      </div>
    </Fade>
  )
}

export default ArticleCard
