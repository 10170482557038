import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Fade from 'react-reveal/Fade'

const Innovation = ({ title, innovation }) => {
  const contRef = React.useRef(null)

  const scrollTop = (ref) => {
    setTimeout(() => {
      const $snapWrapper = document.querySelector('.snap-wrapper')
      const headerHeight = window.innerWidth < 900 ? 62 : 93
      const scrollPos = $snapWrapper.scrollTop + ref.current.getBoundingClientRect().top - headerHeight
      $snapWrapper.scrollTo({ top: scrollPos, behavior: 'smooth' })
    }, 100)
  }

  // State
  const [toggle, setToggle] = React.useState(false)

  const toggleActive = () => {
    setToggle(!toggle)
    scrollTop(contRef)
  }

  return (
    <div className={`accordion__item ${toggle ? 'active' : ''}`}>
      <button className='accordion__title' ref={contRef} onClick={toggleActive}>
        <h4>{title}</h4>
        <span className='accordion__toggle' />
      </button>
      <div className='accordion__content'>
        <div className='innovation'>
          <div className='innovation__inner'>
            <div className='about'>
              <Fade distance='40px' bottom>
                <div className='about__inner'>
                  <div className='about__content' dangerouslySetInnerHTML={{ __html: innovation.introduction }} />
                </div>
              </Fade>
            </div>
            <Fade distance='40px' bottom>
              <div className='innovation__wrap'>
                <div className='innovation__sidebar'>
                  <h3>{innovation.sidebarTitle}</h3>
                </div>
                <div className='innovation__main'>
                  <div className='columns'>
                    <div className='columns__left'>
                      <div className='innovation__content' dangerouslySetInnerHTML={{ __html: innovation.content }} />
                    </div>
                    <div className='columns__right'>
                      <GatsbyImage image={getImage(innovation.image.localFile)} alt={`Entertech - Innovation`} />
                      <div className='innovation__caption' dangerouslySetInnerHTML={{ __html: innovation.image.caption }} />
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Innovation
