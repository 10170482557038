import React from 'react'

export const PrevArrow = ({ onClick, style, className }) => {
  let attr = { onClick, style, className }
  return (
    <span
      {...attr}
    >
      <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_24_2660)">
          <path d="M-3.49691e-07 8L8.25309 16L9.45373 14.8362L3.24972 8.82243L22 8.82243L22 7.17757L3.24972 7.17757L9.45373 1.16381L8.25309 -2.2681e-06L-3.49691e-07 8Z" fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_24_2660">
            <rect width="16" height="22" fill="white" transform="translate(0 16) rotate(-90)" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export const NextArrow = ({ onClick, style, className }) => {
  let attr = { onClick, style, className }
  return (
    <span
      {...attr}
    >
      <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_24_2658)">
          <path d="M22 8L13.7469 -3.60754e-07L12.5463 1.16382L18.7503 7.17757L1.59361e-06 7.17757L1.52171e-06 8.82243L18.7503 8.82243L12.5463 14.8362L13.7469 16L22 8Z" fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_24_2658">
            <rect width="16" height="22" fill="white" transform="translate(22) rotate(90)" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}