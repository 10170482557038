import React from 'react'

import Projects from '../components/projects'
import Practice from '../components/practice'
import Innovation from '../components/innovation'
import News from '../components/news'

const renderBlock = (param, el, i, pageContext) => {
  el.key = i
  el.pageContext = pageContext

  let block = {
    'Projects' : (el) => <Projects {...el} />,
    'Practice' : (el) => <Practice {...el} />,
    'Innovation' : (el) => <Innovation {...el} />,
    'News' : (el) => <News {...el} />,
  }[param]

  if (!block) return null

  return block(el)
}

export default renderBlock