import React from 'react'

import useCategoryQuery from "../hooks/use-category-query"
import useProjectQuery from "../hooks/use-project-query"

import ProjectCard from './cards/project'
import SingleProject from './cards/single-project'

const Projects = ({ title, showFilters, pageContext }) => {
  // Data
  const categories = useCategoryQuery()
  const projects = useProjectQuery()

  // State
  const [activeProject, setActiveProject] = React.useState(projects?.length - 1 || 0)
  const [showProject, setShowProject] = React.useState(false)
  const [activeFilters, setActiveFilters] = React.useState([])
  const [list, setList] = React.useState(projects)
  const [limit, setLimit] = React.useState(12)
  const [toggle, setToggle] = React.useState(false)
  const activeRef = React.useRef(null)
  const contRef = React.useRef(null)

  const scrollTop = (ref) => {
    setTimeout(() => {
      const $snapWrapper = document.querySelector('.snap-wrapper')
      const headerHeight = window.innerWidth < 900 ? 62 : 93
      const sliderHeight = window.innerHeight
      if (!ref.current) return;
      window.scrollTo({ top: sliderHeight })
      const scrollPos = $snapWrapper.scrollTop + ref.current.getBoundingClientRect().top - headerHeight
      $snapWrapper.scrollTo({ top: scrollPos, behavior: 'smooth' })
    }, 200)
  }

  React.useEffect(() => {
    if (pageContext.type === 'project') {
      const index = projects.findIndex(el => el.id === pageContext.id)
      if (index > -1) {
        setActiveProject(index)
        setToggle(true)
        setShowProject(true)
        scrollTop(activeRef)
      }
    }
  }, [pageContext.id])

  React.useEffect(() => {
    let results = projects
    if (results && activeFilters.length > 0) {
      results = projects.filter((post) => {
        let match = false
        post.categories?.nodes?.map(el => {
          if (activeFilters.includes(el.name)) match = true
        })
        return match
      })
    }
    setList(results)
  }, [activeFilters])

  const onFilterChange = React.useCallback((name) => {
    if (name === '') {
      setActiveFilters([])
    } else {
      setActiveFilters(!activeFilters.includes(name) ? [name] : [])
    }
  }, [activeFilters])

  const onClick = React.useCallback((i, nav) => {
    // Toggle if clicked again
    if (showProject && i === activeProject) {
      setShowProject(false)
      setActiveProject(list?.length - 1 || 0)
      window.history.pushState({}, '', `/`)
      window.title = `Studio Entertech`
      scrollTop(contRef)
      return;
    }
    setShowProject(true)
    if (nav === 'prev') {
      i = i === 0 ? list.length - 1 : i - 1
    }
    if (nav === 'next') {
      i = i === list.length - 1 ? 0 : i + 1
    }
    setActiveProject(i)
    window.history.pushState({}, '', list[i].uri)
    document.title = list[i].seo.title
    scrollTop(activeRef)
  }, [list, activeProject]);

  const onClose = React.useCallback((i) => {
    setShowProject(false)
    setActiveProject(list?.length - 1 || 0)
    window.history.pushState({}, '', `/`)
    window.title = `Studio Entertech`
    scrollTop(contRef)
  }, []);

  const toggleActive = () => {
    setToggle(!toggle)
    scrollTop(contRef)
  }

  const renderProjects = () => {
    return (
      <div className={`projects__items ${showProject ? 'active' : ''}`}>
        {list?.slice(0, limit).map((el, i, arr) => {
          let activeDisplay = null
          if (((i + 1) % 3 === 0 || i + 1 === arr.length) && activeProject <= i && activeProject >= i - 2) {
            activeDisplay = (
              <div ref={activeRef} style={{width: '100%'}}>
                {showProject &&
                  <SingleProject {...list[activeProject]} i={activeProject} onClose={onClose} onClick={onClick} />
                }
              </div>
            )
          }
          return (
            <React.Fragment key={el.id}>
              <ProjectCard {...el} i={i} onClick={onClick} isActive={activeProject === i} />
              {activeDisplay}
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  return (
    <div className={`accordion__item accordion__item--projects ${toggle ? 'active' : ''}`} ref={contRef}>
      <button id='projects' className='accordion__title' onClick={toggleActive}>
        <h4>{title}</h4>
        <span className='accordion__toggle' />
      </button>
      <div className='accordion__content'>
        {showFilters &&
          <div className='projects__filter'>
            <ul>
              <li className={activeFilters.length === 0 ? 'active' : ''}><button onClick={() => onFilterChange('')}>All</button></li>
              {categories?.map((el, i) => (
                <li key={i} className={activeFilters.includes(el.name) ? 'active' : ''}><button onClick={() => onFilterChange(el.name)}>{el.name}</button></li>
              ))}
            </ul>
          </div>
        }
        {renderProjects()}
        {limit <= list.length &&
          <p className='news__more'>
            <button onClick={() => setLimit(limit + 6)}>Load More</button>
          </p>
        }
        {list?.length === 0 && <p className='projects__no-results'>No projects at this time.</p>}
      </div>


    </div>
  )
}

export default Projects
