import { useStaticQuery, graphql } from "gatsby"

const useProjectQuery = () => {
  const { allWpProject } = useStaticQuery(graphql`
    query Projects {
      allWpProject(sort: {menuOrder: ASC}) {
        nodes {
          ...ProjectCard
        }
      }
    }
  `)

  return allWpProject.nodes 
}

export default useProjectQuery