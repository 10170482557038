import React, { Component } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../../utils/link'
import Slider from 'react-slick'
import { NextArrow, PrevArrow } from '../../utils/slider-arrows'
import ShareLinks from '../../utils/share-links'

const SingleProject = ({ title, acf, onClose, onClick, i, uri }) => {

  const [slideIndex, setSlideIndex] = React.useState(1)

  const settings = {
    speed: 600,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    cssEase: 'ease',
    swipe: false,
    focusOnSelect: false,
    adaptiveHeight: true,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setSlideIndex(next + 1)
  }

  return (
    <div className='single-project'>
      <div className='single-project__header'>
        <h1>{title}</h1>
        <button onClick={() => onClose(i)}>
          <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1.29289" y1="26.2929" x2="26.2929" y2="1.29289" stroke="black" strokeWidth="2" />
            <line x1="1.70711" y1="1.29289" x2="26.7071" y2="26.2929" stroke="black" strokeWidth="2" />
          </svg>
        </button>
      </div>
      <div className='single-project__wrapper'>
        <div className='single-project__wrapper-inner'>
          <div className='single-project__sidebar'>
            <ul>
              {acf.client &&
                <li>
                  <h4>Client</h4>
                  <p>{acf.client}</p>
                </li>
              }
              {acf.client &&
                <li>
                  <h4>Location</h4>
                  <p>{acf.location}</p>
                </li>
              }
              {acf.completionDate &&
                <li>
                  <h4>Completion Date</h4>
                  <p>{acf.completionDate}</p>
                </li>
              }
            </ul>
            <div className='single-project__content' dangerouslySetInnerHTML={{ __html: acf.content }} />
            {acf.awards?.length &&
              <div className='single-project__awards'>
                <h5>Awards</h5>
                {acf.awards?.map((el, i) => (
                  <p key={i} dangerouslySetInnerHTML={{ __html: el.text }} />
                ))}
              </div>
            }
            <h4 className='single-project__share-title'>Share</h4>
            <div className='single-project__share'>
              <ShareLinks uri={uri} title={title} />
            </div>
            <div className='single-project__nav'>
              <button onClick={() => onClick(i, 'prev')}>Previous project</button><span>/</span><button onClick={() => onClick(i, 'next')}>Next project</button>
            </div>
          </div>
          <div className='single-project__body'>
            {acf.carousel &&
              <div className='single-project__images'>
                  <Slider {...settings}>
                    {acf.gallery?.map((el, i) => (
                      <div className='single-project__image' key={i}>
                        <GatsbyImage image={getImage(el.localFile)} alt={title} />
                        <span dangerouslySetInnerHTML={{ __html: el.caption || '&nbsp;' }} />
                      </div>
                    ))}
                  </Slider>
                <span className='single-project__image-length'>{slideIndex} / {acf.gallery.length}</span>
              </div>
            }
            {!acf.carousel &&
              <div className='single-project__images'>
                {acf.gallery?.map((el, i) => (
                  <div className='single-project__image' key={i}>
                    <GatsbyImage image={getImage(el.localFile)} alt={title} />
                    <span dangerouslySetInnerHTML={{ __html: el.caption }} />
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleProject

