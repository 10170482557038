import React from 'react'

export const Logo = () => (
  <svg width="218" height="34" viewBox="0 0 218 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={'black'}>
      <path d="M10.7478 16.5377C10.4088 16.2604 10.0467 15.9907 9.6576 15.7288C9.27238 15.4668 8.86404 15.2126 8.42873 14.966C7.19216 14.1956 6.25991 13.5522 5.62814 13.0437C4.99637 12.5352 4.67664 11.8341 4.67664 10.9366V6.20984C4.67664 5.40857 4.82302 4.81147 5.11579 4.42624C5.40856 4.04102 5.89395 3.8484 6.5758 3.8484C7.25765 3.8484 7.72762 4.03331 7.98957 4.40313C8.25153 4.77294 8.38251 5.37775 8.38251 6.20984V12.2309H12.7356V5.5588C12.7047 3.82914 12.1962 2.47315 11.2062 1.48312C10.22 0.493089 8.69069 0 6.62203 0C4.55336 0 2.89304 0.531611 1.82982 1.59869C0.762746 2.66191 0.231135 4.12191 0.231135 5.97485V11.3487C0.261953 12.5237 0.47768 13.4944 0.878314 14.2688C1.27895 15.0392 1.81826 15.7056 2.50011 16.2604C2.83911 16.5377 3.20893 16.8074 3.61341 17.0693C4.01405 17.3313 4.43009 17.601 4.8654 17.8783C6.04033 18.5563 6.94176 19.1457 7.57353 19.6388C8.2053 20.1319 8.52504 20.9062 8.52504 21.954V27.4666C8.52504 28.2409 8.38636 28.8264 8.10899 29.2271C7.83163 29.6277 7.32313 29.828 6.57965 29.828C5.83616 29.828 5.28144 29.62 5.00408 29.2039C4.72672 28.7879 4.58803 28.1792 4.58803 27.3741V20.3784H0V27.6053C0 29.7664 0.500793 31.3728 1.50623 32.4244C2.50782 33.4761 4.18355 34 6.53342 34C8.69454 34 10.3086 33.4761 11.3757 32.4244C12.4389 31.3766 12.9744 29.801 12.9744 27.6977V21.4455C12.9744 20.2397 12.7741 19.2536 12.3734 18.4793C11.9728 17.7088 11.4296 17.0578 10.7516 16.5339" />
      <path d="M14.2227 4.58424H18.021V33.584H22.4665V4.58424H26.311V0.369873H14.2227V4.58424Z" />
      <path d="M36.7349 26.727C36.7349 27.7787 36.5885 28.526 36.2957 28.9729C36.003 29.4197 35.5022 29.6432 34.7895 29.6432C34.0768 29.6432 33.576 29.4197 33.2833 28.9729C32.9905 28.526 32.8441 27.7748 32.8441 26.727V0.369873H28.3062V27.1007C28.3062 29.5083 28.8532 31.2611 29.9511 32.359C31.0451 33.4569 32.6592 34.0039 34.7933 34.0039C36.9275 34.0039 38.4992 33.4569 39.6125 32.359C40.7258 31.265 41.2805 29.5122 41.2805 27.1007V0.369873H36.7426V26.727H36.7349Z" />
      <path d="M52.9914 26.311C52.9914 27.4551 52.7603 28.2872 52.298 28.8111C51.8357 29.335 51.1539 29.5969 50.2602 29.5969H48.8233V4.21442H50.2602C51.1231 4.21442 51.7972 4.45326 52.2749 4.93094C52.7526 5.40862 52.9914 6.23686 52.9914 7.40794V26.3071V26.311ZM55.7034 1.89922C54.4206 0.878371 52.6986 0.369873 50.5375 0.369873H44.3315V33.584H50.3988C52.5908 33.584 54.3435 33.0678 55.6572 32.0316C56.9708 30.9992 57.6257 29.2271 57.6257 26.727V7.22689C57.6257 4.69596 56.9862 2.92007 55.7034 1.89922Z" />
      <path d="M65.1724 0.369873H60.542V33.584H65.1724V0.369873Z" />
      <path d="M77.1258 26.5883C77.1258 27.6399 76.9486 28.4258 76.5942 28.9497C76.2398 29.4736 75.6889 29.7356 74.9493 29.7356C74.1442 29.7356 73.574 29.4736 73.235 28.9497C72.896 28.4258 72.7265 27.6361 72.7265 26.5883V7.1806C72.7265 6.09812 72.896 5.29685 73.235 4.77294C73.574 4.24904 74.1288 3.98708 74.9031 3.98708C75.6774 3.98708 76.2013 4.24904 76.5711 4.77294C76.9409 5.29685 77.1258 6.10197 77.1258 7.1806V26.5921V26.5883ZM74.9493 0C72.8806 0 71.2203 0.577838 69.9683 1.73737C68.7164 2.8969 68.0923 4.61885 68.0923 6.90324V27.0544C68.0923 29.462 68.7087 31.2225 69.9452 32.3358C71.1818 33.4491 72.8306 34.0039 74.9031 34.0039C76.9756 34.0039 78.6629 33.4491 79.884 32.3358C81.1052 31.2225 81.7139 29.4659 81.7139 27.0544V6.90324C81.7139 4.61885 81.1129 2.8969 79.9072 1.73737C78.7014 0.577838 77.0488 0 74.9493 0" />
      <path d="M96.0715 18.853H101.492V14.5924H96.0715V4.58809H102.093V0.369873H91.626V33.584H102.231V29.2772H96.0715V18.853Z" />
      <path d="M114.323 17.5086L114.786 23.5797L113.769 19.0841L108.395 0.369873H104.643V33.584H108.626V16.3067L108.16 10.328L109.134 14.7311L114.693 33.584H118.353V0.369873H114.323V17.5086Z" />
      <path d="M120.437 4.58424H124.235V33.584H128.685V4.58424H132.529V0.369873H120.437V4.58424Z" />
      <path d="M139.059 18.853H144.479V14.5924H139.059V4.58809H145.08V0.369873H134.613V33.584H145.219V29.2772H139.059V18.853Z" />
      <path d="M161.849 4.58424H165.647V33.584H170.092V4.58424H173.941V0.369873H161.849V4.58424Z" />
      <path d="M180.47 18.853H185.891V14.5924H180.47V4.58809H186.491V0.369873H176.025V33.584H186.63V29.2772H180.47V18.853Z" />
      <path d="M195.155 0C193.087 0 191.449 0.554725 190.244 1.66803C189.038 2.78133 188.437 4.49173 188.437 6.81079V27.0082C188.437 29.4466 188.992 31.2225 190.105 32.3358C191.218 33.4491 192.883 34.0039 195.109 34.0039C197.336 34.0039 198.915 33.4645 200.044 32.3821C201.173 31.2996 201.75 29.5584 201.781 27.1468V19.3653H197.193V26.7308C197.162 27.7825 197.008 28.5452 196.731 29.0229C196.454 29.5006 195.914 29.7394 195.109 29.7394C194.304 29.7394 193.803 29.4929 193.51 28.9998C193.218 28.5067 193.071 27.7016 193.071 26.5921V7.1806C193.071 6.09812 193.218 5.29685 193.51 4.77294C193.803 4.24904 194.335 3.98708 195.109 3.98708C195.883 3.98708 196.454 4.22592 196.731 4.7036C197.008 5.18128 197.162 5.96329 197.193 7.04192V13.5753H201.781V6.62588C201.75 4.34149 201.188 2.66576 200.09 1.59869C198.992 0.531611 197.347 0 195.155 0" />
      <path d="M213.172 0.369873V14.7311H209.004V0.369873H204.512V33.584H209.004V19.0379H213.172V33.584H217.668V0.369873H213.172Z" />
      <path d="M156.012 13.4791C156.012 14.3112 155.835 14.9237 155.481 15.3089C155.126 15.6941 154.514 15.8868 153.651 15.8868H151.983V4.1682H153.605C154.498 4.1682 155.126 4.3454 155.481 4.69981C155.835 5.05421 156.012 5.65131 156.012 6.4834V13.4791ZM158.786 18.2405C159.599 17.3314 160.088 16.1526 160.261 14.7195C160.331 14.3035 160.369 13.8451 160.369 13.3366V6.11358C160.369 4.10656 159.876 2.64656 158.886 1.73742C157.896 0.828291 156.247 0.369873 153.928 0.369873H147.63V33.584H151.983V19.9817H153.759C153.828 19.9817 153.894 19.9778 153.963 19.974L156.016 33.5802H160.697L158.216 18.7682C158.424 18.6064 158.617 18.4331 158.79 18.2366" />
    </g>
  </svg>
)

export const DownArrow = () => (
  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 22L16 13.7469L14.8362 12.5463L8.82243 18.7503L8.82243 1.27987e-06L7.17757 1.13607e-06L7.17757 18.7503L1.16382 12.5463L-1.18584e-06 13.7469L8 22Z" fill="black"/>
  </svg>
)