import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    fragment WpSeo on WpPostTypeSEO {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        localFile {
          publicURL
        }
      }
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
      schema {
        articleType
        pageType
        raw
      }
      title
      twitterDescription
      twitterImage {
        localFile {
          publicURL
        }
      }
      twitterTitle
    }
    fragment ArticleCard on WpPost {
      id
      uri
      title
      content
      excerpt
      seo {
        ...WpSeo
      }
      featuredImage {
        node {
          desktopImage: localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 1000
              )
            }
          }
          mobileImage: localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 750
              )
            }
          }
          altText
          caption
        }
      }
      date(formatString: "D/MM/YYYY")
      acf {
        content {
          __typename
          ... on WpPost_Acf_Content_Text {
            content
          }
          ... on WpPost_Acf_Content_Gallery {
            gallery {
              caption
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1600
                  )
                }
              }
            }
          }
        }
      }
    }
    fragment ProjectCard on WpProject {
      id
      uri
      title
      seo {
        ...WpSeo
      }
      categories {
        nodes {
          name
          link
        }
      }
      acf {
        thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 667
              )
            }
          }
        }
        client
        location
        completionDate
        content
        awards {
          text
        }
        gallery {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          }
          caption
        }
        carousel
      }
    }
    fragment TeamCard on WpTeam {
      id
      uri
      title
      acf {
        position
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
              )
            }
          }
        }
        email {
          url
          title
        }
        bio
      }
    }
    fragment ProjectBanner on WpProject {
      id
      uri
      title
      acf {
        homeSlider {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1800
                )
              }
            }
          }
          title
          tagline
        }
      }
    }
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wp {
        generalSettings {
          title
          url
        }
        siteOptions {
          acf {
            address
            email
            instagram
            linkedin
            phone
            twitter
            acknowledgement
            googleMaps
            careers
          }
        }
      }
      wpPage(uri:{eq: "/"}) {
        acf {
          aboutUs
          homeBanner {
            ... on WpProject {
              ...ProjectBanner
            }
          }
          contentBlocks {
            ... on WpPage_Acf_ContentBlocks_Projects {
              fieldGroupName
              title
              showFilters
            }
            ... on WpPage_Acf_ContentBlocks_Practice {
              fieldGroupName
              title
              practice {
                ourWork {
                  description
                  list {
                    text
                  }
                  quote {
                    phrase
                    person
                    title
                    company
                  }
                }
                team {
                  ... on WpTeam {
                    ...TeamCard
                  }
                }
                careers
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1800
                      )
                    }
                  }
                }
                history {
                  intro
                  bodyContent
                }
              }
            }
            ... on WpPage_Acf_ContentBlocks_Innovation {
              fieldGroupName
              title
              innovation {
                introduction
                sidebarTitle
                content
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1200
                      )
                    }
                  }
                  caption
                }
              }
            }
            ... on WpPage_Acf_ContentBlocks_News {
              fieldGroupName
              title
            }
          }
        }
      }
    }
  `)
}

export default useLayoutQuery