import React from 'react'
import Link from '../utils/link'

const Footer = ({ data }) => {

  const [email, setEmail] = React.useState('')
  const [success, setSuccess] = React.useState(false)

  const submitForm = (event) => {
    event.preventDefault()

    fetch(`https://hooks.zapier.com/hooks/catch/2181815/bnrxesa/?email=${email}`).then((res) => {
      setSuccess(true)
    }).catch((error) => {
    })

  }

  const { wp } = data

  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <div className='footer__wrapper'>
          <div className='footer__sidebar'>
            <h3>Contact</h3>
            <p>Copyright © { new Date().getFullYear() }</p>
          </div>
          <div className='footer__main'>
            <div className='footer__cols'>
              <div className='footer__col footer__col--middle'>
                <div className='footer__col-top'>
                  <div className='footer__address'>
                    <p dangerouslySetInnerHTML={{ __html: wp.siteOptions.acf.address }} />
                    {wp.siteOptions.acf.googleMaps && <Link to={wp.siteOptions.acf.googleMaps}>View on Google Maps</Link>}
                  </div>
                  <div className='footer__contact'>
                    <Link to={`tel:${wp.siteOptions.acf.phone}`}>{wp.siteOptions.acf.phone}</Link>
                    <Link to={`mailto:${wp.siteOptions.acf.email}`}>{wp.siteOptions.acf.email}</Link>
                    <Link to={`mailto:${wp.siteOptions.acf.careers}`}>{wp.siteOptions.acf.careers}</Link>
                  </div>
                </div>
                <div className='footer__social'>
                  {wp.siteOptions.acf.twitter && (<Link to={wp.siteOptions.acf.twitter}>Twitter</Link>)} —
                  {wp.siteOptions.acf.linkedin && (<Link to={wp.siteOptions.acf.linkedin}>LinkedIn</Link>)} —
                  {wp.siteOptions.acf.instagram && (<Link to={wp.siteOptions.acf.instagram}>Instagram</Link>)}
                </div>
              </div>
              <div className='footer__col'>
                <h4>Subscribe to our newsletter</h4>
                { success ? (
                  <p>{'Thank you for signing up!'}</p>
                )
                  : (
                <form onSubmit={submitForm}>
                  <input type='email' placeholder='email' name='email' required onChange={e => setEmail(e.target.value)} />
                  <button type='submit' className='btn btn--green'>Subscribe</button>
                </form>
                  )}
                <div className='footer__acknowledgement' dangerouslySetInnerHTML={{ __html: wp.siteOptions.acf.acknowledgement }} />
                <p className='copy'>Copyright © { new Date().getFullYear() }</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
