import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../../utils/link'
import Fade from 'react-reveal/Fade'

const ProjectCard = ({ title, uri, acf, i, onClick, isActive, id }) => {

  return (
    <Fade distance='40px' bottom>
      <div className={`card card--project ${isActive ? 'active' : ''}`} data-id={`${id}`} onClick={() => onClick(i)}>
        <div className='card__image'>
          { acf?.thumbnail?.localFile && <GatsbyImage loading='lazy' image={getImage(acf.thumbnail?.localFile)} alt={title} /> }
          <div className='card__rollover'>
            <h4 dangerouslySetInnerHTML={{ __html: title }} />
            <button>View Project</button>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default ProjectCard
