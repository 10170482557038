import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../../utils/link'
import Fade from 'react-reveal/Fade'

const TeamCard = ({ title, acf }) => {

  return (
    <Fade distance='40px' bottom>
      <div className='card card--team'>
        <h4><span dangerouslySetInnerHTML={{ __html: title }} /> — <br /><em>{acf.position}</em></h4>
        { acf.email?.title && <Link className='card__email' to={acf.email.url}>{acf.email.title}</Link> }
        { acf.image?.localFile &&
          <div className='card__image'>
            { acf?.image?.localFile && <GatsbyImage loading='lazy' image={getImage(acf.image?.localFile)} alt={title} /> }
          </div>
        }
        { acf.bio && <div className='card__bio' dangerouslySetInnerHTML={{ __html: acf.bio }} /> }
      </div>
    </Fade>
  )
}

export default TeamCard
