import React from 'react'
import Link from './link'
import { useStaticQuery, graphql } from 'gatsby'

const ShareLinks = ({uri, title}) => {


  const data = useStaticQuery(graphql`
    query ShareLinks {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const url = data.site.siteMetadata.siteUrl + uri

  return (
    <>
      <Link to={`mailto:&subject=Check this out! ${title}&body=${title} ${url}`}>Email</Link>
      <span>—</span>
      <Link to={`//twitter.com/intent/tweet?text=${title} - ${url}`}>Twitter</Link>
      <span>—</span>
      <Link to={`//www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`}>LinkedIn</Link>
    </>
  )
}

export default ShareLinks 